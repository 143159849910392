<template>
  <v-container>
    <v-card
        class="mx-auto text-center pa-5"
        max-width="600">
      <v-card-title>
        Ajouter un Utilisateur
      </v-card-title>
      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
      >
        <v-text-field
            v-model="user.lastname"
            :counter="10"
            label="Nom"
            required
        ></v-text-field>

        <v-text-field
            v-model="user.firstname"
            :counter="10"
            label="Prénom"
            required
        ></v-text-field>

        <v-text-field
            label="Email"
            name="Email"
            prepend-icon="email"
            type="text"
            color="#14ad95"
            v-model.trim="user.email"
        />

        <v-text-field
            id="password"
            label="Mot de passe"
            name="password"
            prepend-icon="lock"
            type="password"
            color="#14ad95"
            v-model.trim="user.password"
        />

        <v-text-field
            v-model="user.shop"
            name="input-7-1"
            label="Boutique"
            hint="Le mans/Orléans"
        ></v-text-field>

        <v-switch
            v-model="user.franchise"
            label="Franchisé"
        ></v-switch>

        <v-file-input
            v-model="user.image"
            label="Image"
            @change="uploadImage"
        ></v-file-input>

        <v-select
            :items="items"
            label="Role"
            solo
        ></v-select>

        <v-checkbox
            v-model="checkbox"
            :rules="[v => !!v || 'You must agree to continue!']"
            label="Do you agree?"
            required
        ></v-checkbox>

        <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="create"
        >
          Validate
        </v-btn>

        <v-btn
            color="error"
            class="mr-4"
            @click="reset"
        >
          Reset Form
        </v-btn>

      </v-form>
    </v-card>
    <v-card>
      <v-simple-table>
        <template v-slot:default>
          <thead> Listes des Utilisateurs
          <tr>
            <th class="text-left">
              Nom
            </th>
            <th class="text-left">
              Prénom
            </th>
            <th class="text-left">
              Email
            </th>
            <th class="text-left">
              Boutique
            </th>
            <th class="text-left">
              Role
            </th>
            <th class="text-left">
              Image
            </th>
            <th class="text-left">
              Actions
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="user in users" :key="user.name"
          >
            <td>{{ user.lastname }}</td>
            <td>{{ user.firstname }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.shop }}</td>
            <td>{{ user.role }}</td>
            <td><v-img :src="user.image" max-height="50" max-width="50"/></td>
            <td>
              <v-btn color="teal" outlined small rounded ><v-icon>mdi-loupe</v-icon></v-btn> |
              <v-btn color="yellow" outlined small rounded ><v-icon>mdi-swap-horizontal</v-icon></v-btn> |
              <v-btn color="red" outlined small rounded @click="deleteUser(user.lastname)" ><v-icon>mdi-delete</v-icon></v-btn>
              <v-dialog
                  v-model="dialog"
                  width="500"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      elevation="2"
                      fab
                      v-bind="attrs"
                      v-on="on"
                      @click="editUser(user)"
                  >
                    <v-icon>
                      fa-plus
                    </v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-form>
                    <v-text-field
                        v-model="user.lastname"
                        :counter="10"
                        label="Nom"
                        required
                    ></v-text-field>

                    <v-text-field
                        v-model="user.firstname"
                        :counter="10"
                        label="Prénom"
                        required
                    ></v-text-field>

                    <v-text-field
                        label="Email"
                        name="Email"
                        prepend-icon="email"
                        type="text"
                        color="#14ad95"
                        v-model.trim="user.email"
                    />

                    <v-text-field
                        id="password"
                        label="Mot de passe"
                        name="password"
                        prepend-icon="lock"
                        type="password"
                        color="#14ad95"
                        v-model.trim="user.password"
                    />

                    <v-textarea
                        v-model="user.shop"
                        name="input-7-1"
                        label="Boutique"
                        hint="Le mans/Orléans"
                    ></v-textarea>

                    <v-switch
                        v-model="user.franchise"
                        label="Franchisé"
                    ></v-switch>

                    <v-file-input
                        v-model="user.image"
                        label="Image"
                        @change="uploadImage"
                    ></v-file-input>

                    <v-select
                        :items="items"
                        label="Role"
                        solo
                    ></v-select>

                    <v-checkbox
                        v-model="checkbox"
                        :rules="[v => !!v || 'You must agree to continue!']"
                        label="Do you agree?"
                        required
                    ></v-checkbox>

                    <v-btn
                        :disabled="!valid"
                        color="success"
                        class="mr-4"
                        @click="create"
                    >
                      Validate
                    </v-btn>

                    <v-btn
                        color="error"
                        class="mr-4"
                        @click="reset"
                    >
                      Reset Form
                    </v-btn>
                  </v-form>
                </v-card>
              </v-dialog>

            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-container>
</template>

<script>
import {addDoc, collection} from "firebase/firestore";
import firebase from "firebase/compat";
import {createUserWithEmailAndPassword, getAuth} from "firebase/auth";
import {db} from "../../main";
export default {
  name: "Products",
  data: () => ({
    valid: true,
    dialog: false,
    userId: "2",
    file: '',
    items: ["SuperAdmin", "Admin", "Franchise"],
    usersRef: firebase.database().ref("users"),
    users:[],
    user:{
      lastname: '',
      firstname: '',
      email: '',
      password: '',
      shop: null,
      franchise: false,
      role: "",
      file: '',
      image: null,
    },
    uploadTask: null,
    uploadState: null,
    activeItem:null,
    checkbox: false,
  }),

  firestore(){
    return{
      users: firebase.firestore.collection("users"),
    }
  },
  methods: {
    async getUser(){
      await firebase.firestore.collection("users").get().then((querySnapshot) => {
        const users = []
          querySnapshot.forEach((doc) => {

          users.push(doc.data());
        })
        this.users = users
      });
    },
    create: function () {
      const auth = getAuth();
      createUserWithEmailAndPassword(auth, this.user.email, this.user.password)
          .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            console.log(user);
            //Enregistrement de l'utilisateur en bdd
            this.saveUserToUsersRef(user).then( async () => {
              //this.$store.dispatch("setUser", user)
              await addDoc(collection(db, "users"), user);
                  this.$router.push('/')
            }).then ( () => {

            },error => {
              console.log(error)
              this.errors.push(error.message)
              this.isLoading = false
            })
          })
          .catch((error) => {
            const errorCode = error.code;
            console.log(errorCode);
            const errorMessage = error.message;
            console.log(errorMessage);
          });
    },
    saveUserToUsersRef(user){
      return this.usersRef.child(user.uid).set({
        name: user.firstname,
        avatar: user.image
      })
    },
    updateUser(){
      firebase.firestore.collection("products")
          .where('name', '==', this.product.name).get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              doc.ref.set({
                name: this.product.name,
                description: this.product.description,
                price: this.product.price,
                image: this.product.image,
                quantity: this.quantity
              }).then(this.getProducts)
            })
          })
    },
    deleteUser(name){
      if(confirm("Are you sure ?")){
        firebase.firestore.collection("products")
            .where('name', '==', name).get()
            .then(querySnapshot => {
              querySnapshot.forEach(doc => {
                doc.ref.delete().then(this.getProducts)
              })
            })
      }
    },
    uploadImage(){
      let file = this.user.file
      let storageRef = firebase.storage().ref("users/"+file.name)
      this.uploadTask = storageRef.put(file);
      this.uploadTask.on("state_changed", () =>{console.log("next step")
        this.uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) =>{
          console.log("File available at", downloadURL);
          this.user.image = downloadURL
        })
      })
      return this.user.image
    },
    validate () {
      this.$refs.form.validate()
    },
    reset () {
      this.$refs.form.remove()
    },
  },
  created(){
    this.getUser();
  },
}
</script>

<style scoped>

</style>